import { matchPath } from "react-router-dom";

const Routes = [
    {
        path: "/",
        exact: true,
        componentName: "Home",
        pageType: "index",
    },
    {
        path: "/agents",
        exact: true,
        componentName: "Agents",
        pageType: "agents",
    },
    {
        path: `/agents/:agent`,
        exact: true,
        componentName: "Agent",
        pageType: "agent",
    },
    {
        path: "/account",
        componentName: "Account",
    },
    {
        path: "/search",
        exact: true,
        componentName: "Search",
    },
    // We want to redirect to the wordpress site any time we hit a path that starts with /info
    {
        path: "/info*",
        componentName: (things) => {
            const {
                location: { pathname },
            } = things;
            const origin =
                window.location.hostname === "localhost"
                    ? "https://testing4.lakehomes.com"
                    : window.location.origin;
            window.location.href = `${origin}${pathname}`;
            return null;
        },
    },
    {
        path: "/:state/:niche/communities",
        exact: true,
        componentName: "Communities",
    },
    {
        path: `/:state?/:niche?/:three?/:community?`,
        componentName: "MapSidebar",
        pageType: "mapSidebarRoutes",
    },
    {
        path: "*",
        exact: true,
        componentName: "NotFound",
    },
];

export const mapSidebarRoutes = [
    {
        path: `/:state/offmarket/:niche`,
        exact: true,
        pageType: "nicheItem",
    },
    {
        path: "/:state/:niche",
        exact: true,
        pageType: "nicheItem",
    },
    {
        path: "/:state/:niche/communities/:community",
        exact: true,
        pageType: "nicheItem_development",
    },
    // This will end up turning into the listing page when agent is moved.
    {
        path: `/:state/:niche/:three`,
        pageType: "listing|agent|nicheItem_development",
    },
    {
        path: "/:state",
        exact: true,
        pageType: "state",
    },
];

/**
 * Finds a route that matches the given path and returns its associated pageType.
 * @param {string} path - The URL path to match with a route.
 * @returns {string} The pageType associated with the matching route.
 */
export const matchRoute = (path) => {
    let route = Routes.find((route) => matchPath(path, route));
    if (route?.pageType === "mapSidebarRoutes") {
        route = mapSidebarRoutes.find((route) => matchPath(path, route));
    }
    return route?.pageType;
};

export default Routes;
