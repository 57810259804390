import React from "react";
import loadable from "@loadable/component";
import { Provider } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { MapProvider } from "./contexts/map";
import CookieProvider from "./components/CookieProvider";
import routes from "./routes";
import PageErrorBoundry from "./components/Pages/PageErrorBoundry";
import PageHandler from "./components/Pages/PageHandler";
import PageScroller from "./components/Pages/PageScroller";
import SearchProvider from "./components/Search/SearchProvider";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import ToastProvider from "./components/ToastProvider";
import NotFound from "./components/NotFound";

/**
 * Importing the page routes here rather than in the routes file
 * to prevent circular dependencies.
 */

const Home = loadable(() =>
    import(/* webpackChunkName: "HomePage" */ "./components/Pages/Home")
);
const Agents = loadable(() =>
    import(/* webpackChunkName: "AgentsPage" */ "./components/Pages/Agents")
);
const Agent = loadable(() =>
    import(/* webpackChunkName: "AgentPage" */ "./components/Pages/Agent")
);

const Account = loadable(() =>
    import(/* webpackChunkName: "AccountPage" */ "./components/Pages/Account")
);
const Search = loadable(() =>
    import(/* webpackChunkName: "SearchPage" */ "./components/Pages/Search")
);
const MapSidebar = loadable(() =>
    import(
        /* webpackChunkName: "MapSidebarPage" */ "./components/Pages/MapSidebar"
    )
);

// This redirect should only happen locally as we are adding a redirect on the server
const Communities = (things) => {
    console.log("Communities redirect");
    const { match, location } = things;
    const {
        params: { state, niche },
    } = match;
    const to = { ...location, pathname: `/${state}/${niche}#communities` };
    return <Redirect to={to} />;
};

const routeComponentMap = {
    Home: Home,
    Agents: Agents,
    Account: Account,
    Search: Search,
    MapSidebar: MapSidebar,
    Agent: Agent,
    NotFound: NotFound,
    Communities: Communities,
};

function App({ store }) {
    const history = useHistory();

    // Set history location state and remove from window object.

    if (typeof window !== "undefined" && window !== null) {
        if (window.locationState) {
            // Setting originalLocation in the dataLayer to use in GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                originalLocation:
                    document.location.protocol +
                    "//" +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search,
            });

            // Replacing initial history state on first load
            // To make sure the initial page history has the app state.
            const { pathname, hash, search } = history.location;
            history.replace(
                `${pathname}${hash}${search || ""}`,
                window.locationState
            );

            // We want to delete window.locationState, but other parts of the app need
            // this data before location state is set by the history replace
            // the timeout is necessary so the data is still on the window object
            // for timing purposes.
            setTimeout(() => (window.locationState = undefined), 3000);
        }
    }

    return (
        <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Provider store={store}>
                <MapProvider>
                    <CookieProvider>
                        <PageErrorBoundry>
                            <ToastProvider>
                                <PageHandler>
                                    <SearchProvider>
                                        <PageScroller>
                                            <Switch>
                                                <Redirect
                                                    exact
                                                    from="/index"
                                                    to="/"
                                                />
                                                {routes.map((route, i) => (
                                                    <Route
                                                        key={`route-key-${i}`}
                                                        {...route}
                                                        component={
                                                            routeComponentMap[
                                                                route
                                                                    .componentName
                                                            ]
                                                        }
                                                    />
                                                ))}
                                            </Switch>
                                        </PageScroller>
                                    </SearchProvider>
                                </PageHandler>
                            </ToastProvider>
                        </PageErrorBoundry>
                    </CookieProvider>
                </MapProvider>
            </Provider>
        </QueryParamProvider>
    );
}

export default App;
