import React from "react";
import IndeterminateButton from "../IndeterminateButton";
import { useUserLogout } from "../../hooks/logout";
import { useCookies } from "react-cookie";

/**
 * Logs the user out, then reloads and clears FE caches
 * @param children {React.ReactNode} - The content of the button
 * @param props {object} - Other props to pass to the button
 *
 */
const Logout = ({ children, ...props }) => {
    const [cookie, setCookie, remove] = useCookies(["sessionId"]);
    const { execute, isLoading } = useUserLogout();

    const handleLogout = async () => {
        await execute(); // logs the user out in the DB
        remove(); // removes the cookie (this will be reset on load)

        if (window.location.pathname.includes("account")) {
            // Remove search params if on an account page
            window.location.href = `${window.location.origin}${window.location.pathname}`;
        } else {
            location.reload(); // reload the page if not in account path
        }
    };

    return (
        <IndeterminateButton
            data-testid="account-logout"
            {...props}
            isLoading={isLoading}
            onClick={async () => await handleLogout()}
        >
            {children}
        </IndeterminateButton>
    );
};

export default Logout;
