import React from "react";
import ToggleButton from "../Search/ToggleButton";
import { FaLock, FaUnlock } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { setMode } from "../../reducers/rootReducer";

const AdminToggle = ({ text }) => {
    const dispatch = useDispatch();
    const { isMobile, mode } = useSelector((state) => state.root);
    const isActive = mode === "edit" ? true : false;
    const LockIcon = isActive ? FaUnlock : FaLock;

    const adminToggle = () => {
        if (mode === "edit") {
            dispatch(setMode("normal"));
        } else if (mode === "normal") {
            dispatch(setMode("edit"));
        }
    };

    return (
        <div
            className={`d-flex mt-1 me-1 py-1 align-items-center ${
                isMobile ? "w-100" : "w-70"
            }`}
        >
            <div className="d-flex align-items-center px-0 ms-auto">
                <LockIcon className="me-2" />
                {text}
            </div>
            <ToggleButton
                className="mx-3"
                onToggle={adminToggle}
                value={isActive}
            />
        </div>
    );
};

export default AdminToggle;
