import React from "react";
import { Input } from "reactstrap";

/**
 * Toggle Button component.
 * @param {function} onToggle - The function that gets called to handle the toggle on/off.
 * @param {boolean} value - The value that determines the state of the toggle.
 * @param {string} className - Any additional classNames for the outer `<div />` (e.g. margin/padding).
 * @returns A toggle button switch.
 */
const ToggleButton = ({ onToggle, value, className }) => {
    return (
        <div className={`form-switch ${className || ""}`}>
            <Input
                type="switch"
                checked={value}
                onChange={(e) => onToggle(!e.target.checked)}
                style={{
                    transform: "scale(1.6)",
                }}
            />
        </div>
    );
};

export default ToggleButton;
